<template>
  <div v-if="me" class="container-fluid">
    <div class="row">
      <div v-if="!me.settings.displayHistory && !me.settings.displayTasks && !me.settings.displayNotifications && !me.settings.displayCalendar && !me.settings.displayOffers" class="text-sm text-center">
        Kokpit jest pusty!
        Moduły możesz dodać w swoich <router-link to="/home/profile/settings" :class="`text-${ color }`">ustawieniach</router-link>!
      </div>

      <div v-if="me.settings.displayHistory || me.settings.displayTasks || me.settings.displayNotifications" class="col-lg-5">
        <!-- Historia -->
        <div v-if="me.settings.displayHistory" class="card mb-4">
          <div class="card-header pb-1">
            <h6 class="font-weight-bold">Historia</h6>
          </div>
          <div class="card-body pt-1">
            <div v-if="history.length === 0" class="text-sm">Brak historii</div>
            <label v-for="(item, index) in history" :key="index" class="w-100">
              <div class="d-flex">
                <material-avatar
                  :img="$getAvatar(item.owner.fullName, item.owner.avatar)"
                  class="me-3 shadow"
                  circular
                  alt="avatar image"
                />
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ item.owner.fullName }} <span class="font-weight-normal">{{ item.description }}</span></h6>
                  <p class="mb-0 text-xs text-secondary opacity-7">{{ item.createdAt }}</p>
                </div>
              </div>
              <hr v-if="index !== history.length - 1" class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'" />
            </label>
          </div>
        </div>

        <!-- Zadania -->
        <div v-if="me.settings.displayTasks" class="card mb-4">
          <div class="card-header pb-1">
            <h6 class="font-weight-bold">Zadania</h6>
          </div>
          <div class="card-body pt-1">
            <div v-if="tasks.length === 0" class="text-sm">Brak zadań</div>
            <div v-for="(item, index) in tasks" :key="item" class="w-100">
              <div class="d-flex align-items-center">
                <material-checkbox class="me-3" :checked="item.mark" @change="item.mark = true; patchTask(item.id)" />
                <div class="cursor-pointer" @click="$store.state.openedTask = item.id; $redirect('Zadania')">
                  <span class="mb-0 text-sm">{{ item.name }}</span>
                  <p class="mb-0 text-xs opacity-7">{{ moment(item.closedAt).format("DD/MM/YYYY") }}</p>
                </div>
              </div>
              <hr v-if="index !== tasks.length - 1" class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'" />
            </div>
          </div>
        </div>

        <!-- Powiadomienia -->
        <div v-if="me.settings.displayNotifications" class="card mb-4">
          <div class="card-header pb-1">
            <h6 class="font-weight-bold">Powiadomienia</h6>
          </div>
          <div class="card-body pt-1">
            <div v-if="notifications.length === 0" class="text-sm">Brak powiadomień</div>
            <label
              v-for="(item, index) in notifications"
              :key="index"
              class="w-100 cursor-pointer"
              @click="item.event ? patchNotification(item.id, 'event', item.event.id) : item.sale ? patchNotification(item.id, 'sale', item.sale.id) : patchNotification(item.id, null, null)"
            >
              <div class="d-flex">
                <div
                  class="icon icon-shape shadow text-center me-3"
                  :class="isDarkMode ? 'bg-gradient-light' : 'bg-gradient-dark'"
                  style="width: 57px !important; border-radius: 50%"
                >
                  <i class="material-icons opacity-10 pt-1" :class="isDarkMode ? 'text-dark' : 'text-light'">notifications</i>
                </div>

                <div class="d-flex flex-column justify-content-center w-100">
                  <h6 class="mb-1 text-sm font-weight-normal">
                    <span :class="item.isRead ? '' : 'font-weight-bold'">{{ item.description }}</span>
                  </h6>
                  <p class="mb-0 text-xs opacity-7">
                    {{ item.createdAt }}
                  </p>
                </div>
              </div>
              <hr v-if="index !== notifications.length - 1" class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'" />
            </label>
          </div>
        </div>
      </div>

      <div v-if="me.settings.displayCalendar || me.settings.displayOffers" class="col-lg-7">
        <!-- Kalendarz -->
        <div v-if="me.settings.displayCalendar" class="card mb-4">
          <div class="card-header pb-1">
            <h6 class="font-weight-bold">Kalendarz</h6>
          </div>
          <my-calendar :events="events"/>
        </div>

        <!-- Sprzedaż -->
        <div v-if="me.settings.displayOffers" class="card mb-4">
          <div class="card-header pb-1">
            <h6 class="font-weight-bold">Sprzedaż</h6>
          </div>
          <div class="card-body pt-1">
            <div v-if="sales.length === 0" class="text-sm">Brak sprzedaży</div>
            <div v-for="(item, index) in sales" :key="item" class="text-sm cursor-pointer" @click="$store.state.openedSale = item.id; $redirect('Sprzedaż')">
              <span :class="isDarkMode ? 'text-white' : 'text-dark'">
                {{ item.name }}
              </span>
              <hr v-if="index !== sales.length - 1" class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyCalendar from "@/views/home/calendar/components/Calendar.vue"
import MaterialAvatar from "@/components/MaterialAvatar.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import UserDataService from "@/services/UserDataService"
import UserHistoryDataService from "@/services/UserHistoryDataService"
import TaskDataService from "@/services/TaskDataService"
import SaleDataService from "@/services/SaleDataService"
import CalendarDataService from "@/services/CalendarDataService"
import NotificationDataService from "@/services/NotificationDataService"
import SettingsDataService from "@/services/SettingsDataService"
import { mapState } from "vuex"
import _ from "lodash"

export default {
  name: "Dashboard",
  components: {
    MyCalendar,
    MaterialAvatar,
    MaterialCheckbox,
  },
  mixins: [
    MyCalendar
  ],
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika
      history: [], // Tablica zawierająca dane historii z API
      events: [], // Tablica zawierająca dane wydarzeń z API
      tasks: [], // Tablica zawierająca dane zadań z API
      sales: [], // Tablica zawierająca dane sprzedaży z API
      notifications: [], // Tablica zawierająca dane powiadomień z API
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"]),

    sorted() {
      return _.orderBy(this.mailbox, "date", "desc")
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(uid) {
      UserDataService.get(uid)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }

        this.me = res.data
        
        if (!this.me.settings) {
          this.postSettings(res.data.id)
        }

        if (this.me.calendars.length === 0) {
          this.postCalendar(res.data.id, res.data.fullName)
        }

        if (res.data.settings.displayHistory) {
          this.getHistories()
        }

        if (res.data.settings.displayTasks) {
          this.getTasks()
        }

        if (res.data.settings.displayOffers) {
          this.getSales()
        }

        if (res.data.settings.displayNotifications) {
          this.getNotifications(res.data.id)
        }

        if (res.data.settings.displayCalendar) {
          this.getEvents()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wczytuje dane historii z API
    getHistories() {
      UserHistoryDataService.getAll("?order[createdAt]=desc")
      .then(res => {
        this.history = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wczytuje dane zadań z API
    getTasks() {
      let groups = ""
      this.me.tasksGroups.forEach(item => {
        groups += `&group.name[]=${ item.name }`
      })

      TaskDataService.getAll(`?status[]=todo&status[]=late&order[closedAt]=asc&closedAt[after]=${ this.moment().format("YYYY-MM-DD") }${ groups }`)
      .then(res => {
        this.tasks = res.data["hydra:member"]
        this.tasks.forEach(item => {
          item.mark = false
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja zmienia status zadania
    patchTask(id) {
      TaskDataService.status(id,
        {
          id: id,
          status: "done"
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.getTasks()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja wczytuje dane sprzedaży z API
    getSales() {
      SaleDataService.getAll(`?itemsPerPage=5&member.id=${ this.me.id }&order[closedAt]=asc&closedAt[after]=${ this.moment().format("YYYY-MM-DD") }`)
      .then(res => {
        this.sales = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wydarzenia zalogowanego użytkownika
    getEvents() {
      CalendarDataService.getAll(`?members.id=${ this.me.id }`)
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          item.events.forEach(event => {
            event.startRecur = null
            event.daysOfWeek = null

            if (event.repeat !== "never") {
              event.permissions = false
              event.startRecur = event.startDate

              let eventStartDate = this.moment(event.startDate).format("YYYY-MM-DD HH:mm:ss")
              let eventEndRecur = this.moment(event.endRecur).format("YYYY-MM-DD HH:mm:ss")

              // Codziennie
              if (event.repeat === "daily") {
                for(let i = 1; eventStartDate < eventEndRecur; i++) {
                  eventStartDate = this.moment(event.startDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss")
                  if (eventStartDate < eventEndRecur) {
                    this.events.push({
                      id: event.type === "sale" ? event.stageHistory.sale.id : event.id,
                      title: event.title,
                      start: this.moment(event.startDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss"),
                      end: this.moment(event.endDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss"),
                      repeat: event.repeat,
                      allDay: event.allDay,
                      className: "cursor-pointer",
                      backgroundColor: item.color,
                      editable: false,
                      type: event.type
                    })
                  }
                }
              }

              // Co tydzień
              if (event.repeat === "weekly") {
                for(let i = 7; eventStartDate < eventEndRecur; i += 7) {
                  eventStartDate = this.moment(event.startDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss")
                  if (eventStartDate < eventEndRecur) {
                    this.events.push({
                      id: event.type === "sale" ? event.stageHistory.sale.id : event.id,
                      title: event.title,
                      start: this.moment(event.startDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss"),
                      end: this.moment(event.endDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss"),
                      repeat: event.repeat,
                      allDay: event.allDay,
                      className: "cursor-pointer",
                      backgroundColor: item.color,
                      editable: false,
                      type: event.type
                    })
                  }
                }
              }

              // Co dwa tygodnie
              if (event.repeat === "biweekly") {
                for(let i = 14; eventStartDate < eventEndRecur; i += 14) {
                  eventStartDate = this.moment(event.startDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss")
                  if (eventStartDate < eventEndRecur) {
                    this.events.push({
                      id: event.type === "sale" ? event.stageHistory.sale.id : event.id,
                      title: event.title,
                      start: this.moment(event.startDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss"),
                      end: this.moment(event.endDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss"),
                      repeat: event.repeat,
                      allDay: event.allDay,
                      className: "cursor-pointer",
                      backgroundColor: item.color,
                      editable: false,
                      type: event.type
                    })
                  }
                }
              }

              // Co miesiąc
              if (event.repeat === "monthly") {
                for(let i = 1; eventStartDate < eventEndRecur; i += 1) {
                  eventStartDate = this.moment(event.startDate).add(i, "months").format("YYYY-MM-DD HH:mm:ss")
                  if (eventStartDate < eventEndRecur) {
                    this.events.push({
                      id: event.type === "sale" ? event.stageHistory.sale.id : event.id,
                      title: event.title,
                      start: this.moment(event.startDate).add(i, "months").format("YYYY-MM-DD HH:mm:ss"),
                      end: this.moment(event.endDate).add(i, "months").format("YYYY-MM-DD HH:mm:ss"),
                      repeat: event.repeat,
                      allDay: event.allDay,
                      className: "cursor-pointer",
                      backgroundColor: item.color,
                      editable: false,
                      type: event.type
                    })
                  }
                }
              }

              // Co rok
              if (event.repeat === "yearly") {
                for(let i = 1; eventStartDate < eventEndRecur; i++) {
                  eventStartDate = this.moment(event.startDate).add(i, "years").format("YYYY-MM-DD HH:mm:ss")
                  if (eventStartDate < eventEndRecur) {
                    this.events.push({
                      id: event.type === "sale" ? event.stageHistory.sale.id : event.id,
                      title: event.title,
                      start: this.moment(event.startDate).add(i, "years").format("YYYY-MM-DD HH:mm:ss"),
                      end: this.moment(event.endDate).add(i, "years").format("YYYY-MM-DD HH:mm:ss"),
                      repeat: event.repeat,
                      allDay: event.allDay,
                      className: "cursor-pointer",
                      backgroundColor: item.color,
                      editable: false,
                      type: event.type
                    })
                  }
                }
              }
            }
            
            this.events.push({
              id: event.type === "sale" ? event.stageHistory.sale.id : event.id,
              title: event.title,
              start: event.startDate,
              end: event.endDate,
              repeat: event.repeat,
              allDay: event.allDay,
              className: "cursor-pointer",
              backgroundColor: item.color,
              editable: false,
              type: event.type
            })
          })
        })

        this.me.tasksGroups.forEach(item => {
          item.tasks.forEach(task => {
            this.events.push({
              id: task.id,
              title: task.name,
              start: task.closedAt,
              end: task.closedAt,
              allDay: true,
              className: "cursor-pointer",
              backgroundColor: item.color,
              editable: false,
              type: "task",
              icon: task.status
            })
          })
        })

        this.createCalendar("450px", false, this.getView(), this.getDate())
      })
      .catch(error => {
        console.log(error)
      })

      this.createCalendar("450px", false, this.getView(), this.getDate())
    },

    // Funkcja pobierająca powiadomienia obecnie zalogowanego użytkownika
    getNotifications(id) {
      NotificationDataService.getAll(`?page=1&itemsPerPage=5&recipient.id=${ id }&order[createdAt]=desc&createdAt[before]=${ this.moment().format("YYYY-MM-DD HH:mm") }`)
      .then(res => {
        this.notifications = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja zmieniająca status powiadomienia na przeczytane
    patchNotification(id, type, typeId) {
      NotificationDataService.patch(id,
        {
          isRead: true
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.getNotifications(this.me.id)

        if (type === "event") {
          this.$store.state.openedEvent = typeId
          this.$redirect("Kalendarz")
        }
        else if (type === "sale") {
          this.$store.state.openedSale = typeId
          this.$redirect("Sprzedaż")
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca ustawienia, jeśli brakuje
    postSettings(id) {
      SettingsDataService.post(
        {
          owner: `/users/${ id }`,
          displayHistory: true,
          displayMailbox: true,
          displayCustomers: true,
          displayOffers: true,
          displayCalendar: true,
          displayTasks: true,
          displayNotifications: true,
          messagesCount: 5,
          sendNotifications: true,
          playSound: true,
          pushNotifications: true,
          displayMail: true,
          darkMode: false,
          sidebarColor: "primary",
          sidebarType: "bg-gradient-dark",
        }
      )
      .then(res => {
        console.log(res.data)
        this.getMe()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca kalendarz, jeśli brakuje
    postCalendar(id, fullName) {
      CalendarDataService.post(
        {
          name: fullName,
          owner: `/users/${ id }`,
          members: [`/users/${ id }`],
          color: "#e91e63",
          privacy: true,
        }
      )
      .then(res => {
        console.log(res.data)
        this.getMe()
      })
      .catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}
</style>