<template>
  <div v-if="me" class="container-fluid">
    <!-- Tabela: Zamówienia -->
    <div class="row">
      <div class="mb-4" :class="order ? 'col-md-8' : 'col-12'">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Zamówienia</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <!-- Wyszukiwarka -->
                <div class="dataTable-top mb-3 py-0">
                  <div class="dataTable-search">
                    <SimpleTypeahead
                      ref="searchRef"
                      v-model="filter_name"
                      placeholder="Wyszukaj..."
                      :items="ordersAC"
                      :min-input-length="0"
                      class="text-sm px-3 py-2 border-secondary"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                      @select-item="selectItem"
                    ></SimpleTypeahead>
                  </div>
                  <div class="dataTable-search"></div>
                </div>
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <thead class="thead-light">
                      <!-- Kolumny -->
                      <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('id')">ID</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 60%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('name')">Nazwa</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 30%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('createdAt')">Data utworzenia</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in orders"
                        :key="item"
                        :class="order && order.id === item.id ? 'bg-light' : ''"
                      >
                        <!-- ID -->
                        <td class="cursor-pointer" @click="order && order.id === item.id ? order = null : getOrder(item.id)">
                          <p class="m-0 text-xs">#{{ item.id }}</p>
                        </td>

                        <!-- Nazwa -->
                        <td class="ps-0 cursor-pointer" @click="order && order.id === item.id ? order = null : getOrder(item.id)">
                          <p class="m-0 text-xs">{{ item.name }}</p>
                        </td>

                        <!-- Data utworzenia -->
                        <td class="ps-0 cursor-pointer" @click="order && order.id === item.id ? order = null : getOrder(item.id)">
                          <p class="m-0 text-xs">{{ item.createdAt }}</p>
                        </td>

                        <!-- Menu -->
                        <td class="text-xs text-end">
                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-icons cursor-pointer">more_vert</i>
                          </a>
                          <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                            <li class="cursor-pointer" @click="getOrder(item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal">
                                      Podgląd
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <!-- Brak zamówień -->
                      <tr :class="orders.length > 0 ? 'd-none' : ''">
                        <td class="dataTables-empty text-sm" colspan="5">Nie znaleziono zamówień</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Paginacja -->
                <div v-if="orders.length > 0" class="dataTable-bottom pb-3">
                  <div class="dataTable-dropdown">
                    <label>
                      <select
                        class="dataTable-selector text-sm p-2 border-secondary me-2"
                        style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        @change="onChangeRecordsPerPage($event)"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="1000000000">Wszystkie</option>
                      </select>
                      pozycji na stronę
                    </label>
                  </div>

                  <div class="dataTable-pagination">
                    <ul class="dataTable-pagination-list">
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(1)">‹‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">
                          {{ page - 1 }}
                        </a>
                      </li>
                      <li class="active">
                        <a href="#" :style="isDarkMode ? 'background: #f0f2f5 !important; color: #344767 !important' : 'background: #344767 !important; color: #f0f2f5 !important'">
                          {{ page }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">
                          {{ page + 1 }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">›</a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(lastPage)">››</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Zamówienie -->
      <div v-if="order" class="col-md-4">
        <div class="card position-sticky top-1 overflow-auto">
          <div class="card-header pb-1">
            <h5>{{ order.name }}</h5>
          </div>
          <div class="card-body pt-1 pb-0">
            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Prestashop ID: <span class="font-weight-normal ms-2">{{ order.externalId ? order.externalId : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Kontrahent: <span class="font-weight-normal ms-2 cursor-pointer" @click="$store.state.customer = order.customer.id; $redirect('Kontrahenci')">{{ order.customer ? order.customer.company : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Cena: <span class="font-weight-normal ms-2">{{ order.price ? order.price : "0.00" }} PLN</span>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Status: <span class="font-weight-normal ms-2">{{ order.state ? order.state : "Brak" }}</span>
                </label>
              </div>
            </div>

            <h5 class="my-3">Produkty</h5>

            <div class="row">
              <div class="col-12">
                <ol class="px-3 text-sm">
                  <li v-for="item in order.products" :key="item" class="mb-1 cursor-pointer" @click="$store.state.product = item.id; $redirect('Produkty')">{{ item.name }}</li>
                </ol>
              </div>
            </div>

            <div class="row my-3">
              <div class="col-12 d-flex justify-content-end">
                <material-button
                  color="light"
                  variant="gradient"
                  @click="order = null"
                >
                  Anuluj
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import OfferDataService from "@/services/OfferDataService"
import SimpleTypeahead from "vue3-simple-typeahead"
import { mapState } from "vuex"

export default {
  name: "Orders",
  components: {
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      order: null, // Obiekt wybranego zamówienia

      orders: [], // Tablica zawierająca dane zamówień z API
      ordersAC: [], // Tablica zawierająca nazwy zamówień z API

      column: "id", // Nazwa filtrowanej kolumny
      columnOrder: "asc", // Wartość filtrowania

      page: 1, // Obecna strona
      lastPage: 1, // Ostatnia strona

      filter_name: "", // Filtr: Nazwa
      filter_recordsPerPage: 10, // Filtr: Ilość rekordów na stronę
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  watch: {
    filter_name(newSearch) {
      this.filter_name = newSearch
      this.getOrders(1)
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted) {
          this.$logout()
        }
        else {
          this.me = res.data
          this.getOrders(this.page)
          this.getOrdersAC()
        }
        
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wybrane zamówienie z API
    getOrder(id) {
      OfferDataService.get(id)
      .then(res => {
        this.order = res.data
        console.log(this.order)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca zamówienia z API
    getOrders(page) {
      OfferDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&name=${ this.filter_name }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.orders = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) === 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca nazwy zamówień z API
    getOrdersAC() {
      OfferDataService.getAll("?itemsPerPage=1000000000")
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          this.ordersAC.push(item.name)
        })
      })
    },

    // Funkcja przypisująca wybraną nazwę do zmiennej
    selectItem(item) {
      this.filter_name = item
      this.getOrders(1)
    },

    // Funkcja przypisująca do zmiennej wybraną ilość rekordów na stronę
    onChangeRecordsPerPage(e) {
      this.filter_recordsPerPage = e.target.value
      this.getOrders(1)
    },

    // Funkcja przenosząca na następną stronę
    onNextPage(page) {
      if(page > this.lastPage) {
        page = 1
        this.getOrders(page)
      }
      else {
        this.getOrders(page)
      }
    },

    // Funkcja przenosząca na poprzednią stronę
    onPrevPage(page) {
      if(page < 1) {
        page = this.lastPage
        this.getOrders(page)
      }
      else {
        this.getOrders(page)
      }
    },

    // Funkcja sortująca kolumny w tabeli
    onOrder(column) {
      this.column = column
      if (this.columnOrder === "asc") {
        this.columnOrder = "desc"
        this.getOrders(this.page)
      }
      else {
        this.columnOrder = "asc"
        this.getOrders(this.page)
      }
    }
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.simple-typeahead {
  color: #000;
}
</style>