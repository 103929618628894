<template>
  <div v-if="me" class="container-fluid">
    <div class="d-flex mb-4">
      <!-- Przycisk: Powrót -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Użytkownicy')"
      >
        <i class="material-icons me-2">keyboard_backspace</i> Powrót
      </material-button>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <div class="card position-sticky top-1 mb-4">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#user-data" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">receipt</i>
                <span class="text-sm">Dane użytkownika</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#password" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">lock</i>
                <span class="text-sm">Hasło</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#permissions" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">security</i>
                <span class="text-sm">Uprawnienia</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#group" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">groups</i>
                <span class="text-sm">Grupa</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      
      <div class="col-lg-9">
        <!-- Dane użytkownika -->
        <div id="user-data" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Dane użytkownika</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6 mb-3">
                <material-input
                  id="firstName"
                  variant="static"
                  label="Imię"
                  placeholder="Jan"
                />
              </div>
              <div class="col-md-6 mb-3">
                <material-input
                  id="lastName"
                  variant="static"
                  label="Nazwisko"
                  placeholder="Kowalski"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Adres e-mail
                  </label>
                  <input
                    v-model="user.email"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    placeholder="jan.kowalski@elf24.pl"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Numer telefonu
                  </label>
                  <input
                    v-model="user.phoneNumber"
                    v-mask="'+48 ### ### ###'"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    placeholder="+48 *** *** ***"
                  />
                </div>
              </div>
            </div>
          </div>

          <p v-if="errorData !== ''" class="w-100 text-end px-4 text-sm font-weight-bold text-danger">{{ errorData }}</p>
        </div>

        <!-- Hasło -->
        <div id="password" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Hasło</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row mb-3">
              <material-input
                id="newPassword"
                variant="static"
                type="password"
                label="Hasło"
                placeholder="********"
              />
            </div>
            <div class="row">
              <material-input
                id="confirmNewPassword"
                variant="static"
                type="password"
                label="Potwierdź hasło"
                placeholder="********"
              />
            </div>
          </div>

          <p v-if="errorPassword !== ''" class="w-100 text-end px-4 text-sm font-weight-bold text-danger">{{ errorPassword }}</p>
        </div>

        <!-- Uprawnienia -->
        <div id="permissions" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Uprawnienia</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6">
                <label class="form-label ms-0 mb-1">Prawa</label>
                <select
                  v-model="user.roles[0]"
                  class="w-100 p-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  style="border: none; border-radius: 10px"
                  :style="isDarkMode ? 'background: rgb(0, 0, 0, 0.2)' : 'background: rgb(0, 0, 0, 0.1)'"
                >
                  <option value="ROLE_USER">Użytkownik</option>
                  <option value="ROLE_ADMIN">Administrator</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!-- Grupa -->
        <div id="group" class="card mb-4 z-index-3">
          <div class="card-header pb-1">
            <h5>Grupa</h5>
            <p class="mb-0 text-sm">
              Wybierz grupę, do której ma należeć użytkownik.
            </p>
          </div>
          <div class="card-body pt-1">
            <div v-if="!user.group" class="mt-3 text-sm">Brak podłączonej grupy</div>
            <div v-else class="d-flex align-items-center mt-2">
              <i class="material-symbols-outlined me-2">groups</i>
              <p class="my-auto text-sm">{{ user.group.name }}</p>
              <span
                class="text-danger text-sm icon-move-right my-auto ms-auto me-3 cursor-pointer"
                @click="groups.push(user.group); groupsAC.push(user.group.name); user.group = null"
              >
                Odłącz
              </span>
            </div>

            <div class="row col-md-6 mt-4">
              <label class="form-label ms-0">Podłącz grupę</label>
              <SimpleTypeahead
                ref="groupsAC"
                placeholder="Wybierz grupę z listy..."
                :items="groupsAC"
                :min-input-length="0"
                class="text-sm pb-2"
                style="width: 100%; background: none; border: none; outline: none; border-bottom: 1px solid #eeeeee"
                :class="isDarkMode ? 'text-white' : 'text-dark'"
                @select-item="selectGroup"
              >
              </SimpleTypeahead>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <material-button
            :color="color"
            variant="gradient"
            @click="validation()"
          >
            Dodaj
          </material-button>

          <material-button
            class="ms-3"
            :color="color"
            variant="gradient"
            @click="addAnother = true; validation()"
          >
            Dodaj i utwórz kolejnego
          </material-button>

          <material-button
            class="ms-3"
            color="light"
            variant="gradient"
            @click="$redirect('Użytkownicy')"
          >
            Anuluj
          </material-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import SimpleTypeahead from "vue3-simple-typeahead"
import UserDataService from "@/services/UserDataService"
import UserGroupDataService from "@/services/UserGroupDataService"
import SettingsDataService from "@/services/SettingsDataService"
import CalendarDataService from "@/services/CalendarDataService"
import { mapState } from "vuex"

export default {
  name: "NewUser",
  components: {
    MaterialInput,
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      user: {
        email: "",
        password: "",
        fullName: "",
        phoneNumber: "",
        group: null,
        roles: ["ROLE_USER"],
      }, // Obiekt tworzonego użytkownika

      calendar: {
        name: "",
      }, // Obiekt tworzonego kalendarza użytkownika

      groups: [], // Lista wszystkich dostępnych grup
      groupsAC: [], // Lista nazw wszystkich dostępnych grup

      errorData: "", // Zmienna odpowiedzialna za błąd danych
      errorPassword: "", // Zmienna odpowiedzialna za błąd hasła

      addAnother: false, // Zmienna sprawdzająca czy dodać kolejne konto
    }
  },
  computed: {
    ...mapState([
      "isAbsolute",
      "isDarkMode",
      "color",
    ])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja sprawdzająca czy użytkownik ma uprawnienia
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)
        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprwadzająca status użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else {
          this.me = res.data
          this.getGroups()
        }
      })
      .catch(error => {
        console.log(error)
        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validation() {
      let success = true
      this.errorData = ""
      this.errorPassword = ""

      if (document.getElementById("firstName").value === "" || document.getElementById("lastName").value === "") {
        success = false
        this.errorData = "Niepoprawne dane użytkownika!"
      }
      else {
        this.user.fullName = document.getElementById("firstName").value + " " + document.getElementById("lastName").value
      }

      if (this.user.email === "") {
        success = false
        this.errorData = "Niepoprawne dane użytkownika!"
      }

      if (this.user.phoneNumber === "") {
        success = false
        this.errorData = "Niepoprawne dane użytkownika!"
      }

      if (document.getElementById("newPassword").value !== "" || document.getElementById("confirmNewPassword").value !== "") {
        if (document.getElementById("newPassword").value !== document.getElementById("confirmNewPassword").value) {
          success = false
          this.errorPassword = "Niepoprawne hasło!"
        }
        else {
          this.user.password = document.getElementById("newPassword").value
        }
      }

      if (success) {
        this.postUser()
      }
    },

    // Funkcja dodająca nowego użytkownika do bazy danych
    postUser() {
      let mailAccounts = []
      this.user.mailAccounts.forEach(item => {
        mailAccounts.push(`/mail_accounts/${ item.id }`)
      })

      UserDataService.post(
        {
          fullName: this.user.fullName,
          email: this.user.email,
          phoneNumber: this.user.phoneNumber,
          password: this.user.password,
          group: this.user.group ? `/user_groups/${ this.user.group.id }` : null,
          roles: this.user.roles,
          avatar: "e91e63",
          status: "Nieaktywny",
          deleted: false,
        }
      )
      .then(res => {
        console.log(res.data)
        this.calendar.name = res.data.fullName
        this.postSettings(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }

        if(JSON.stringify(error.response.status) == 500) {
          this.errorData = "Adres e-mail jest już zajęty!"
        }
      })
    },

    // Funkcja dodająca ustawienia dla stworzonego użytkownika
    postSettings(id) {
      SettingsDataService.post(
        {
          owner: `/users/${ id }`,
          displayHistory: true,
          displayMailbox: true,
          displayCustomers: true,
          displayOffers: true,
          displayCalendar: true,
          displayTasks: true,
          displayNotifications: true,
          messagesCount: 5,
          sendNotifications: true,
          playSound: true,
          pushNotifications: true,
          displayMail: true,
          darkMode: false,
          sidebarColor: "primary",
          sidebarType: "bg-gradient-dark",
        }
      )
      .then(res => {
        console.log(res.data)
        this.postCalendar(id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja dodawająca kalendarz dla stworzonego użytkownika
    postCalendar(id) {
      CalendarDataService.post(
        {
          owner: `/users/${ id }`,
          name: this.calendar.name,
          color: "#e91e63",
          privacy: true,
          members: [`/users/${ id }`],
        }
      )
      .then(res => {
        console.log(res.data)
        if(!this.addAnother) {
          this.$redirect("Użytkownicy")
        }
        else {
          this.$router.go(0)
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    
    // Funkcja pobierająca wszystkie grupy z API
    getGroups() {
      UserGroupDataService.getAll("")
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          this.groups.push(item)
          this.groupsAC.push(item.name)
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane o grupie z listy rozwijanej
    selectGroup(group) {
      this.groups.forEach((item, index) => {
        if(item.name === group) {
          if (this.user.group) {
            this.groups.push(this.user.group)
            this.groupsAC.push(this.user.group.name)
          }

          this.user.group = item
          this.groups.splice(index, 1)
          this.groupsAC.splice(index, 1)
        }
      })

      this.$refs.groupsAC.clearInput()
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

select {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}

.simple-typeahead {
  color: #000;
}
</style>