<template>
  <div v-if="me" class="container-fluid">
    <!-- Tabela: Ceny -->
    <div class="row">
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Ceny</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <!-- Wyszukiwarka -->
                <div class="dataTable-top mb-3 py-0">
                  <div class="dataTable-search">
                    <SimpleTypeahead
                      ref="customerRef"
                      v-model="filter_customer"
                      placeholder="Wyszukaj kontrahenta..."
                      :items="customers"
                      :min-input-length="1"
                      class="text-sm px-3 py-2 border-secondary"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                      @select-item="selectCustomer"
                    ></SimpleTypeahead>
                  </div>

                  <div class="dataTable-search">
                    <SimpleTypeahead
                      ref="productRef"
                      v-model="filter_product"
                      placeholder="Wyszukaj produkt..."
                      :items="products"
                      :min-input-length="1"
                      class="text-sm px-3 py-2 border-secondary"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                      @select-item="selectProduct"
                    ></SimpleTypeahead>
                  </div>
                </div>

                <!-- Tabela -->
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <!-- Kolumny -->
                    <thead class="thead-light">
                      <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('id')">ID</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 30%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('customer.acronym')">Kontrahent</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 30%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('product.name')">Produkt</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 15%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('priceNet')">Cena Netto</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 15%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('updatedAt')">Data aktualizacji</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%"></th>
                      </tr>
                    </thead>

                    <!-- Wiersze -->
                    <tbody>
                      <!-- Kontrahenci -->
                      <tr v-for="item in prices" :key="item">
                        <!-- ID -->
                        <td class="cursor-pointer">
                          <p class="m-0 text-xs">#{{ item.id }}</p>
                        </td>

                        <!-- Kontrahent -->
                        <td class="ps-0 cursor-pointer">
                          <p class="m-0 text-xs">{{ item.customer.acronym }}</p>
                        </td>

                        <!-- Produkt -->
                        <td class="ps-0 cursor-pointer">
                          <p class="m-0 text-xs">{{ item.product ? item.product.name : "Brak" }}</p>
                        </td>

                        <!-- Cena -->
                        <td class="ps-0 cursor-pointer">
                          <p class="m-0 text-xs text-capitalize">{{ item.priceNet }}</p>
                        </td>

                        <!-- Data -->
                        <td class="ps-0 cursor-pointer">
                          <p class="m-0 text-xs text-capitalize">{{ moment(item.updatedAt).format("DD/MM/YYYY HH:mm") }}</p>
                        </td>

                        <!-- Menu -->
                        <td class="text-xs text-end">
                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-icons cursor-pointer">more_vert</i>
                          </a>
                          <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                            <li class="cursor-pointer">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Podgląd
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <!-- Brak cen -->
                      <tr :class="prices.length > 0 ? 'd-none' : ''">
                        <td class="dataTables-empty text-sm" colspan="5">Nie znaleziono cen</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Paginacja -->
                <div v-if="prices.length > 0" class="dataTable-bottom pb-3">
                  <div class="dataTable-dropdown">
                    <label>
                      <select
                        class="dataTable-selector text-sm p-2 border-secondary me-2"
                        style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        @change="onChangeRecordsPerPage($event)"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="1000000000">Wszystkie</option>
                      </select>
                      pozycji na stronę
                    </label>
                  </div>

                  <div class="dataTable-pagination">
                    <ul class="dataTable-pagination-list">
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(1)">‹‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">
                          {{ page - 1 }}
                        </a>
                      </li>
                      <li class="active">
                        <a href="#" :style="isDarkMode ? 'background: #f0f2f5 !important; color: #344767 !important' : 'background: #344767 !important; color: #f0f2f5 !important'">
                          {{ page }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">
                          {{ page + 1 }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">›</a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(lastPage)">››</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserDataService from "@/services/UserDataService"
import PriceDataService from "@/services/PriceDataService"
import ProductDataService from "@/services/ProductDataService"
import SimpleTypeahead from "vue3-simple-typeahead"
import { mapState } from "vuex"

export default {
  name: "Prices",
  components: {
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Objekt zalogowanego użytkownika

      prices: [], // Tablica zawierająca dane cen z API

      customers: [], // Tablica zawierająca nazwy kontrahentów z API
      products: [], // Tablica zawierająca nazwy produktów z API

      column: "id", // Nazwa filtrowanej kolumny
      columnOrder: "asc", // Wartość filtrowania

      page: 1, // Obecna strona
      lastPage: 1, // Ostatnia strona

      filter_customer: "", // Filtr: Kontrahent
      filter_product: "", // Filtr: Produkt
      filter_recordsPerPage: 10, // Filtr: Ilość rekordów na stronę
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  watch: {
    filter_customer(newSearch) {
      this.filter_customer = newSearch
      this.getPrices(1)
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted) {
          this.$logout()
        }
        else {
          this.me = res.data
          this.getPrices(this.page)
          this.getCustomers()
          this.getProducts()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca kontrahentów z API
    getPrices(page) {
      PriceDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&customer.acronym=${ this.filter_customer }&product.externalId=${ this.filter_product }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.prices = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca nazwy kontrahentów z API
    getCustomers() {
      PriceDataService.getAll("?itemsPerPage=1000000000000")
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          let exist = false

          this.customers.forEach(customer => {
            if (customer === item.customer.acronym) {
              exist = true
            }
          })

          if (!exist) {
            this.customers.push(item.customer.acronym)
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca nazwy produktów z API
    getProducts() {
      ProductDataService.getAll("")
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          this.products.push(item.externalId)
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja przypisująca wybraną nazwę do zmiennej
    selectCustomer(item) {
      this.filter_customer = item
      this.getPrices(1)
    },

    // Funkcja przypisująca wybraną nazwę do zmiennej
    selectProduct(item) {
      this.filter_product = item
      this.getPrices(1)
    },

    // Funkcja przypisująca do zmiennej wybraną ilość rekordów na stronę
    onChangeRecordsPerPage(e) {
      this.filter_recordsPerPage = e.target.value
      this.getPrices(1)
    },

    // Funkcja przenosząca na następną stronę
    onNextPage(page) {
      if(page > this.lastPage) {
        page = 1
        this.getPrices(page)
      }
      else {
        this.getPrices(page)
      }
    },

    // Funkcja przenosząca na poprzednią stronę
    onPrevPage(page) {
      if(page < 1) {
        page = this.lastPage
        this.getPrices(page)
      }
      else {
        this.getPrices(page)
      }
    },

    // Funkcja sortująca kolumny w tabeli
    onOrder(column) {
      this.column = column
      if (this.columnOrder === "asc") {
        this.columnOrder = "desc"
        this.getPrices(this.page)
      }
      else {
        this.columnOrder = "asc"
        this.getPrices(this.page)
      }
    }
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.simple-typeahead {
  color: #000;
}
</style>